<template>
  <div class="border select">
    <multiselect
      :value="value"
      :options="options"
      @input="onInput"
      :trackBy="trackBy"
      :placeholder="placeholder"
      selectLabel=""
      selectedLabel=""
      :deselectLabel="allowEmpty && $t('shared.select.deselectLabel')"
      deselectedLabel=""
      deselectGroupLabel=""
      :searchable="searchable"
      :allowEmpty="allowEmpty"
      :preselectFirst="preselectFirst"
      :dir="dir"
      :multiple="multiple"
      :close-on-select="!multiple"
      :preserveSearch="true"
      label="name"
    >
      <template slot="beforeList">
        <slot name="beforeList"></slot>
      </template>
      <template slot="option" slot-scope="optionProps">
        <slot name="option" v-bind="optionProps">
          {{ optionProps.option[optionKey] }}
        </slot>
      </template>
      <template slot="singleLabel" v-if="value">
        <slot name="singleLabel" v-bind="value">
          <p class="m-0">{{ value.name }}</p>
        </slot>
      </template>
      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single" v-if="values.length">
          {{ values.map(value => value[optionKey]).join(', ') }}
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    value: {
      required: true
    },
    variant: {
      type: String,
      default: () => 'unstyled'
    },
    trackBy: {
      type: String,
      default: () => 'id'
    },
    optionKey: {
      type: String,
      default: () => 'name'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    allowEmpty: {
      type: Boolean,
      default: () => true
    },
    preselectFirst: {
      type: Boolean,
      default: () => true
    },
    searchable: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  },
  computed: {
    dir() {
      return this.$i18n.locale() === 'ar' ? 'rtl' : 'ltr'
    }
  }
}
</script>

<style lang="scss">
.select {
  .multiselect {
    cursor: pointer;
    min-width: 200px;
    text-align: start;
    .multiselect__tags {
      background: none;
      border: none;
      border-radius: 0;
    }
  }
  .multiselect--above .multiselect__content-wrapper {
    top: 100%;
    bottom: auto;
  }
  .multiselect__tags,
  .multiselect__input,
  .multiselect__content-wrapper {
    background: transparent;
  }
  .multiselect__input {
    padding: 0;
    margin: 0;
  }
  .multiselect__input::placeholder {
    font-size: 14px;
  }

  .multiselect__single {
    background: none;
  }
  .multiselect__option--selected.multiselect__option--highlight {
    color: #008ecf;
    background: #e9e9e9;
  }
  .multiselect__option {
    color: #008ecf;
    background: #fff;
  }
  .multiselect__option:hover {
    color: #008ecf;
    background: #e9e9e9;
  }
  .multiselect__tags-wrap .multiselect__tag {
    display: none;
  }
  .multiselect--active .multiselect__placeholder {
    display: block;
  }
  .multiselect__content-wrapper {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #008ecf;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      outline: 0;
    }
  }
  .multiselect__option--selected.multiselect__option--highlight:after {
    color: #ff6a6a;
    background: #e9e9e9;
  }
}
</style>
