import { api } from '../axios'

const getUserExtension = async () => {
  const res = await api.get('extension/agent-info')
  let agent = res.data.agent

  return agent
}

export default {
  getUserExtension
}
