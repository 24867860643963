export default {
  state: {
    extensionStatus: 'disconnected'
  },
  getters: {
    extensionStatus(state) {
      return state.extensionStatus
    }
  },
  mutations: {
    setExtensionStatus(state, payload) {
      state.extensionStatus = payload
    }
  },
  actions: {
    async extensionLogin({ commit }) {
      commit('setExtensionStatus', 'connected')
    },
    async extensionLogout({ commit }) {
      commit('setExtensionStatus', 'disconnected')
    }
  }
}
