// Polyfills
import 'es6-promise/auto'
import 'babel-polyfill'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import App from './App'
import store from './store'
import router from './router'
import VuesticPlugin from '@/vuestic-theme/vuestic-plugin'
import './i18n'
import YmapPlugin from 'vue-yandex-maps'
import VueSwal from 'vue-swal'
import wysiwyg from 'vue-wysiwyg'
import VTooltip from 'v-tooltip'
// Import component
import Multiselect from 'vue-multiselect'
import ApexCharts from 'apexcharts'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-wysiwyg/dist/vueWysiwyg.css'

Vue.use(wysiwyg, {}) // config is optional. more below
Vue.use(VueSwal)
import ProgressBar from 'vue-simple-progress'
Vue.component('ProgressBar', ProgressBar)
Vue.component('multiselect', Multiselect)
Vue.use(VuesticPlugin)
Vue.use(YmapPlugin)
// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate, { fieldsBagName: 'formFields' })
Vue.use(VTooltip)
import BootstrapVue from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.component('loading', Loading)
// Install BootstrapVue
Vue.use(BootstrapVue)
import 'vue-multiselect/dist/vue-multiselect.min.css'
/* eslint-disable no-new */

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import Select from './elements/Select.vue'
Vue.component('Select', Select)

import VueGates from 'vue-gates'
Vue.use(VueGates)

import VueSocketIO from 'vue-socket.io'
import { io } from 'socket.io-client'

const userType = localStorage.getItem('userType') || 'user'

if (userType === 'agent') {
  Vue.use(
    new VueSocketIO({
      debug: process.env.NODE_ENV !== 'production',
      connection: io(process.env.VUE_APP_SOCKET_API, {
        extraHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }),
      vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
      },
      options: {
        allowEIO3: true
      }
    })
  )
}

// register serviceWorker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(registration => {})
    .catch(error => {})
}

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
