<template>
  <div class="incoming-call-pad flex-fill d-flex flex-column">
    <p>incoming call</p>
    <h5>{{ call.request.from.uri.user }}</h5>
    <h2>{{ call.request.from.display_name }}</h2>
    <div class="">
      <b-badge variant="primary">{{ call.request.from.uri.host }}</b-badge>
    </div>

    <div class="actions mt-auto col-12">
      <div class="row">
        <div class="col">
          <button class="btn btn-block btn-danger p-4" @click="onHangup()">
            <img src="../../assets/icons/hangup.svg" alt="" />
          </button>
        </div>
        <div class="col">
          <button class="btn btn-block btn-primary p-4" @click="onAnswer()">
            <img src="../../assets/icons/phone.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    call: {
      type: Object,
      required: true
    }
  },
  methods: {
    onHangup() {
      this.$emit('hangup')
    },
    onAnswer() {
      this.$emit('answer')
    }
  }
}
</script>

<style lang="scss" scoped></style>
