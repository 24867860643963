export const yupToKV = yupError => {
  let error = {}

  console.log(yupError.inner)
  yupError.inner.forEach(err => {
    const path = err.path
    error[path] = err.message
  })

  return error
}
